.memory-online-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.memory-online-container .home-content {
  height: calc(100vh - 52px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.memory-online-container .join-options {
  display: flex;
  gap: 20px;
}

.memory-online-container .rooms-content {
  height: calc(100vh - 52px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.memory-online-container .rooms-content .rooms-container {
  width: 500px;
  height: 400px;
  background-color: #fff;
}

.memory-online-container .create-room-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.memory-online-container .create-room-content .create-room-container {
  width: 500px;
  height: 400px;
  border-radius: 10px;
  /* background-color: var(--text-color); */
  padding: 16px;
}

.tooltip-info {
  display: flex;
  color: var(--text-color);
  flex-direction: row-reverse;
}

.type-options {
  color: var(--text-color-two);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.create-room-radio label {
  color: var(--text-color-two);
}

.input-group label {
  color: var(--text-color-two);
}

.memory-online-container
  .create-room-content
  .create-room-container
  .create-room-options {
  margin-top: 16px;
}

.memory-online-container
  .create-room-content
  .create-room-container
  .create-room-options
  form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.memory-online-container .single-room-container {
  width: 90%;
  display: flex;
  color: var(--text-color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.turn-row {
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.score-row {
  width: 100%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.one-score {
  color: white;
  text-align: center;
  padding: 10px;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(54, 67, 142, 255) 0%,
    rgba(69, 84, 160, 255) 58%,
    rgba(133, 146, 202, 255) 100%
  );
}

@media (min-width: 400px) {
  .score-row {
    font-size: 15px;
  }
}

@media (min-width: 700px) {
  .score-row {
    width: 90%;
    grid-gap: 20px;
  }

  .type-options {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 840px) {
  .score-row {
    width: 70%;
  }
}

@media (min-width: 990px) {
  .score-row {
    width: 45%;
  }
}

@media (min-width: 1700px) {
  .score-row {
    width: 35%;
    font-size: 20px;
  }
}

.memory-online-container .single-room-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.memory-online-container .single-room-container .card-grid .box {
  max-width: 100px;
  max-height: 100px;
  background-color: #fff;
}

.memory-online-container .single-room-container .card-grid .box img {
  width: 100%;
  height: 100%;
}

.memory-online-container .single-room-container .card-grid .box {
  position: relative;
  width: 100px; /* Adjust as needed */
  height: 140px; /* Adjust as needed */
  perspective: 1000px;
}

.memory-online-container .single-room-container .card-grid .box img {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}

.memory-online-container
  .single-room-container
  .card-grid
  .box.flipped
  img.front {
  transform: rotateY(180deg);
}

.memory-online-container
  .single-room-container
  .card-grid
  .box.flipped
  img.back {
  transform: rotateY(0);
}

.free-rooms-container {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.freeroom-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 5px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  color: var(--text-color);
}

.join-create-button {
  height: 60px;
  margin: 15px;
}

.no-rooms-info {
  color: var(--text-color);
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

.canceled-modal {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title-canceled {
  font-size: 20px;
}

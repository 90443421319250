.battleship-online-container {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.battleship-buttons-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.battleship-red-info {
  background-color: rgba(223, 47, 47, 0.89);
  padding: 10px;
  border-radius: 5px;
  color: var(--text-color);
  margin-bottom: 10px;
}
.battleship-blue-info {
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background-color: #007bff;
  transition: background-color 0.5s ease, transform 0.3s ease;
  transform: scale(1);
  opacity: 1;
}

.battleship-blue-info.scale-up {
  transform: scale(1.1);
  opacity: 0.9;
}

.your-turn {
  background-color: #242f6d;
}

.opponent-turn {
  background-color: #574b4b;
}

@keyframes pulse {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}

.pulse {
  animation: pulse 1.2s infinite;
}

.boats-title {
  color: var(--text-color);
}

.battleship-boards {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pointer-none {
  pointer-events: none;
}

.battleship-board {
  display: grid;
  grid-template-columns: repeat(10, 30px);
  grid-template-rows: repeat(10, 30px);
}

.battleship-cell {
  background-color: rgb(53, 53, 129);
  border: 1px solid rgb(116, 116, 115);
  display: flex;
  justify-content: center;
  align-items: center;
}

.boats-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px;
  gap: 10px;
}

.ready-battleship-button {
  width: 100px;
  height: 30px;
  margin: 10px;
}

.cell-with-boat {
  background-color: rgb(82, 82, 185);
}

.cell-without-boat {
  background-color: rgb(53, 53, 129);
}

.horizontal-boat {
  border: 1px solid grey;
  background-color: rgb(82, 82, 185);
  margin-bottom: 5px;
}

.vertical-boat {
  border: 1px solid grey;
  background-color: rgb(82, 82, 185);
  margin-bottom: 5px;
}

.boat-size-2.horizontal-boat {
  width: 60px;
  height: 30px;
}

.boat-size-2.vertical-boat {
  width: 30px;
  height: 60px;
}

.boat-size-3.horizontal-boat {
  width: 90px;
  height: 30px;
}

.boat-size-3.vertical-boat {
  width: 30px;
  height: 90px;
}

.boat-size-4.horizontal-boat {
  width: 120px;
  height: 30px;
}

.boat-size-4.vertical-boat {
  width: 30px;
  height: 120px;
}

.boat-size-5.horizontal-boat {
  width: 150px;
  height: 30px;
}

.boat-size-5.vertical-boat {
  width: 30px;
  height: 150px;
}

@media (min-width: 650px) {
  .battleship-board {
    grid-template-columns: repeat(10, 40px);
    grid-template-rows: repeat(10, 40px);
  }

  .boat-size-2.horizontal-boat {
    width: 80px;
    height: 40px;
  }

  .boat-size-2.vertical-boat {
    width: 40px;
    height: 80px;
  }

  .boat-size-3.horizontal-boat {
    width: 120px;
    height: 40px;
  }

  .boat-size-3.vertical-boat {
    width: 40px;
    height: 120px;
  }

  .boat-size-4.horizontal-boat {
    width: 160px;
    height: 40px;
  }

  .boat-size-4.vertical-boat {
    width: 40px;
    height: 160px;
  }

  .boat-size-5.horizontal-boat {
    width: 200px;
    height: 40px;
  }

  .boat-size-5.vertical-boat {
    width: 40px;
    height: 200px;
  }
}

@media (min-width: 1100px) {
  .battleship-board {
    grid-template-columns: repeat(10, 50px);
    grid-template-rows: repeat(10, 50px);
  }

  .battleship-boards {
    flex-direction: row;
  }

  .boat-size-2.horizontal-boat {
    width: 100px;
    height: 50px;
  }

  .boat-size-2.vertical-boat {
    width: 50px;
    height: 100px;
  }

  .boat-size-3.horizontal-boat {
    width: 150px;
    height: 50px;
  }

  .boat-size-3.vertical-boat {
    width: 50px;
    height: 150px;
  }

  .boat-size-4.horizontal-boat {
    width: 200px;
    height: 50px;
  }

  .boat-size-4.vertical-boat {
    width: 50px;
    height: 200px;
  }

  .boat-size-5.horizontal-boat {
    width: 250px;
    height: 50px;
  }

  .boat-size-5.vertical-boat {
    width: 50px;
    height: 250px;
  }
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 80vh;
}

.game-banner-home {
  height: 300px;
  border-radius: 15px;
  padding: 0px;
  margin: 0 0 20px 0;
}

.game-banner-home:hover {
  opacity: 0.7 !important;
}

.memory-type-link:first-of-type .image-slide {
  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.memory-type-link:last-of-type .image-slide {
  animation-name: slideInFromRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.memory-type-link:first-of-type .button-slide {
  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.memory-type-link:last-of-type .button-slide {
  animation-name: slideInFromRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@media (max-width: 900px) {
  .game-banner-home {
    height: 200px;
    border-radius: 15px;
    padding: 0px;
    margin: 0 0 10px 0;
  }
  .home-container {
    flex-direction: column;
  }
  .button-slide {
    animation-name: slideInFromTop !important;
  }
  .image-slide {
    animation-name: slideInFromDown !important;
  }
}

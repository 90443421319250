.memory-solo-container {
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  width: 100%;
  padding: 0;
  overflow-x: hidden;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  align-items: center;
}

.turns {
  font-size: 20px;
}

.memory-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.memory-game-row {
  display: flex;
  flex-direction: column;
}

.create-room-button {
  height: 40px;
}

.memory-type-button {
  width: 300px;
}

.left-button {
  margin: 0 10px 10px 0;
}

.memory-type-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #183153;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  border: none;
}

.hoverable-icon {
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth transition */
}

.hoverable-icon:hover {
  transform: scale(1.2); /* Scale the icon */
}

.memory-type-button:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: #ffd401;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
}

.memory-type-button:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.memory-type-button span {
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 18px 25px;
  color: #fff;
  font-size: 1.125em;
  font-weight: 700;
  letter-spacing: 0.3em;
  z-index: 20;
  transition: all 0.3s ease-in-out;
}

.memory-type-button span {
  text-decoration: none;
}

.memory-type-link {
  text-decoration: none !important;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.memory-type-button:hover span {
  color: #183153;
  animation: scaleUp 0.3s ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.memory-type-button-disabled {
  width: 200px;
  margin: 10px 0;
  color: var(--text-color) !important;
}

.card-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.card {
  position: relative;
}

.card img {
  width: 100%;
  display: block;
  border: 2px solid #fff;
  border-radius: 6px;
}

.card .front {
  transform: rotateY(90deg);
  transition: all ease-in 0.2s;
  position: absolute;
}

.flipped .front {
  transform: rotateY(0deg);
  transition-delay: 0.2s;
}

.card .back {
  transition: all ease-in 0.1s;
  transition-delay: 0.2s;
}

.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}

@media (min-width: 700px) {
  .card-grid {
    width: 90%;
    grid-gap: 20px;
  }
  .memory-game-row {
    flex-direction: row;
  }
}

@media (min-width: 840px) {
  .card-grid {
    width: 70%;
  }
}

@media (min-width: 990px) {
  .card-grid {
    width: 45%;
  }
}

@media (min-width: 1700px) {
  .card-grid {
    width: 35%;
  }
}

.overlay-waiting {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.788);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
}

.loader {
  width: 48px;
  height: 48px;
  margin: 0 auto 20px;
  position: relative;
}
.loader:before {
  content: "";
  width: 48px;
  height: 5px;
  background: #f0808050;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #f08080;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {
  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}

.loader-text {
  font-size: 25px;
}

@media (max-width: 345px) {
  .loader-text {
    font-size: 20px;
  }
}
